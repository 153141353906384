<template>
	<div
		v-resize="onResize"
		class="Base"
	>
		<v-btn
			v-show="fab"
			v-scroll="onScroll"
			fab
			dark
			fixed
			bottom
			right
			color="primary"
			class="top-slide"
			:class="[!isMobile? 'top-bottom' : 'top-bottom-mobile']"
			:height="isMobileOnly? '40' : '40'"
			:width="isMobileOnly? '40' : '40'"
			@click="toTop"
		>
			<v-icon>mdi-chevron-up</v-icon>
		</v-btn>

		<a
			v-if="page.socialLinks.w"
			:href="page.socialLinks.w"
			target="_blank"
		>
			<v-btn
				fab
				dark
				fixed
				bottom
				left
				class="top-slide"
				:height="isMobileOnly? '32' : '40'"
				:width="isMobileOnly? '32' : '40'"
			>
				<img
					:height="isMobileOnly? '32' : '40'"
					:width="isMobileOnly? '32' : '40'"
					src="/img/logo/whatsapp.svg"
				>
			</v-btn>
		</a>

		<!-- Internet Issue Snackbar Start -->
		<v-snackbar
			v-if="!$online"
			:value="true"
			:timeout="-1"
			color="error"
			elevation="24"
		>
			Please Check yout internet !!!
		</v-snackbar>
		<!-- Internet Issue Snackbar End  -->

		<!-- Navigation Drawer Start -->
		<v-navigation-drawer
			v-if="isMobile"
			v-model="page.drawerNavigation"
			app
			:style="{background: $vuetify.theme.themes[theme].bg1}"
		>
			<!-- Profile Start -->
			<template #prepend>
				<v-list-item two-line>
					<v-list-item-content>
						<v-list-item-title>
							<img
								src="/img/logo/logo.png"
								class="logo-img"
							>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
			<!-- Profile End -->

			<v-divider />
			<!-- navigation Start -->
			<v-list flat>
				<span
					v-for="(items,id) in page.navigations"
					:key="id"
				>
					<v-subheader>{{ items.title }}</v-subheader>
					<v-list-item-group
						v-for="item in items.value"
						:key="item.title"
						v-model="page.selectedItem"
						color="primary"
					>
						<v-list-group
							v-if="item.nest"
							:prepend-icon="item.icon"
						>
							<template #activator>
								<v-list-item-title>
									{{
										item.title
									}}
								</v-list-item-title>
							</template>

							<span
								v-for="nest in item.nest"
								:key="nest.title"
							>
								<v-list-item
									v-if="!nest.additional"
									link
									class="nested-link"
									:to="nest.link"
									exact
								>
									<v-list-item-icon v-if="nest.icon">
										<v-icon>{{ nest.icon }}</v-icon>
									</v-list-item-icon>
									<v-list-item-title>
										{{ nest.title }}
									</v-list-item-title>
								</v-list-item>
							</span>
						</v-list-group>

						<span v-else>
							<v-list-item
								v-if="!item.additional"
								link
								:to="item.link"
								exact
							>
								<v-list-item-icon v-if="item.icon">
									<v-icon>{{ item.icon }}</v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title>
										{{
											item.title
										}}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</span>
					</v-list-item-group>
				</span>
			</v-list>
			<!-- navigation End -->
		</v-navigation-drawer>
		<!-- Navigation Drawer End -->

		<!-- Top Navigation Start -->
		<div
			class="topbar"
			:style="{background: $vuetify.theme.themes[theme].bg1}"
		>
			<v-container>
				<v-row>
					<v-col
						cols="12"
						md="12"
						class="d-flex align-center"
					>
						<v-btn
							v-if="isMobile"
							icon
							class="ma-4"
							@click.stop="page.drawerNavigation = !page.drawerNavigation"
						>
							<v-icon>mdi-menu</v-icon>
						</v-btn>
						<img
							src="/img/logo/logo.png"
							class="logo-img"
						>
						<v-spacer />
						<span v-if="!isMobile">
							<span
								v-for="(navigation , index) in page.navigations"
								:key="index"
							>
								<span
									v-for="(item , index1) in navigation.value"
									:key="index1"
								>
									<router-link
										v-if="!item.additional"
										:to="item.link"
										exact
										class="ma-2 text-decoration-none navigation-link"
									>
										<span>
											{{ item.title }}
										</span>
									</router-link>
								</span>
							</span>
						</span>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<!-- Top Navigation End -->

		<v-main class="primaryBG">
			<router-view />
		</v-main>

		<div class="footer pt-16 pr-8 pl-8 primaryBG">
			<div class="BigFooter">
				<v-container>
					<v-row>
						<v-col
							cols="12"
							md="2"
						>
							<div class="BigFooterColumn">
								<p class="title text-center primary--text">
									<v-icon color="primary">
										mdi-passport
									</v-icon>
									Services
								</p>
								<v-divider class="my-4" />
								<v-btn
									class="footer-btn"
									block
									plain
								>
									Group Tour
								</v-btn>
								<v-btn
									class="footer-btn"
									block
									plain
								>
									F.I.T. Tour
								</v-btn>
								<v-btn
									class="footer-btn"
									block
									plain
								>
									Air Ticket
								</v-btn>
								<v-btn
									class="footer-btn"
									block
									plain
								>
									Visa Assistance
								</v-btn>
								<v-divider
									class="mt-4 mb-4 d-none d-md-block"
								/>
								<div class="logo-img-footer text-center mt-8 d-none d-md-block">
									<img
										src="/img/logo/logo.png"
										width="200px"
									>
								</div>
							</div>
						</v-col>

						<v-col
							cols="12"
							md="2"
						>
							<div class="BigFooterColumn">
								<p class="title text-center primary--text">
									<v-icon color="primary">
										mdi-link
									</v-icon>
									Other Link
								</p>
								<v-divider class="my-4" />

								<span
									v-for="(navigation , index) in page.navigations"
									:key="index"
									class="footer-btn"
								>
									<v-btn
										v-for="(item , index1) in navigation.value"
										:key="index1"
										block
										plain
									>
										<router-link
											:to="item.link"
											exact
											class="ma-2 text-decoration-none navigation-link"
										>
											<span>{{ item.title }}</span>
										</router-link>
									</v-btn>
								</span>
							</div>
						</v-col>

						<v-col
							cols="12"
							md="4"
						>
							<div class="BigFooterColumn">
								<span>
									<p class="title text-center primary--text">
										<v-icon color="primary">
											mdi-map-marker
										</v-icon>
										Address
									</p>
									<v-divider class="my-4" />
									<p class="text-center">
										{{ page.contact.address }}
									</p>
								</span>

								<span>
									<p class="title text-center primary--text">
										<v-icon color="primary">
											mdi-phone
										</v-icon>
										Mobile No
									</p>
									<v-divider class="my-2" />
									<p class="text-center">
										<a
											:href="'tel: '+page.contact.mobile1_number"
											class="text-decoration-none white--text"
										>
											{{ page.contact.mobile1_number }} - {{ page.contact.mobile1_name }}
										</a>
									</p>
									<p
										v-if="page.contact.mobile2_number"
										class="text-center"
									>
										<a
											:href="'tel: '+page.contact.mobile2_number"
											class="text-decoration-none white--text"
										>
											{{ page.contact.mobile2_number }} - {{ page.contact.mobile2_name }}
										</a>
									</p>
									<p
										v-if="page.contact.mobile3_number"
										class="text-center"
									>
										<a
											:href="'tel: '+page.contact.mobile3_number"
											class="text-decoration-none white--text"
										>
											{{ page.contact.mobile3_number }} - {{ page.contact.mobile3_name }}
										</a>
									</p>
								</span>

								<p class="title text-center primary--text">
									<v-icon color="primary">
										mdi-email
									</v-icon>
									Email
								</p>
								<v-divider class="my-2" />
								<p class="text-center">
									<a
										:href="'mailto: '+page.contact.email1"
										class="text-decoration-none white--text"
									>
										{{ page.contact.email1 }}
									</a>
									<span v-if="page.contact.email2">/</span>
									<a
										v-if="page.contact.email2"
										:href="'mailto: '+page.contact.email2"
										class="text-decoration-none white--text"
									>
										{{ page.contact.email2 }}
									</a>
								</p>
							</div>
						</v-col>

						<v-col
							cols="12"
							md="4"
						>
							<div class="BigFooterColumn">
								<p class="title text-center primary--text">
									<v-icon color="primary">
										mdi-message
									</v-icon>
									Subscribe
								</p>
								<v-divider class="my-4" />
								<p>
									To get best deals on tour Packages,
									<br>
									Subscribe Now !!!
								</p>
								<div class="subscribeForm">
									<v-form
										ref="subscribeForm"
										lazy-validation
									>
										<v-text-field
											v-model="page.subscribe.form.name"
											label="Name *"
											clearable
											:rules="page.subscribe.validation.requiredField"
										/>
										<v-text-field
											v-model="page.subscribe.form.mobile"
											label="Mobile Number *"
											clearable
											:rules="page.subscribe.validation.requiredField"
										/>
									</v-form>
									<v-expand-transition>
										<v-alert
											v-if="page.subscribe.resMsg"
											dense
											text
											type="success"
										>
											{{ page.subscribe.resMsg }}
										</v-alert>
									</v-expand-transition>
									<v-btn
										class="primary"
										block
										:loading="page.subscribe.loading"
										@click="subscribeNow"
									>
										Subscribe
										<v-icon class="ml-2">
											mdi-send
										</v-icon>
									</v-btn>
								</div>
								<div class="socialBtn justify-center align-center text-center my-6">
									<a
										v-if="page.socialLinks.i"
										:href="page.socialLinks.i"
										target="_blank"
										class="ma-2 text-decoration-none navigation-link social-icon"
									>
										<img
											src="/img/logo/instagram.svg"
											width="24px"
											height="24px"
										>
									</a>
									<a
										v-if="page.socialLinks.f"
										:href="page.socialLinks.f"
										target="_blank"
										class="ma-2 text-decoration-none navigation-link social-icon"
									>
										<img
											src="/img/logo/facebook.svg"
											width="24px"
											height="24px"
										>
									</a>
									<a
										v-if="page.socialLinks.t"
										:href="page.socialLinks.t"
										target="_blank"
										class="ma-2 text-decoration-none navigation-link social-icon"
									>
										<img
											src="/img/logo/twitter.svg"
											width="24px"
											height="24px"
										>
									</a>
									<a
										v-if="page.socialLinks.w"
										:href="page.socialLinks.w"
										target="_blank"
										class="ma-2 text-decoration-none navigation-link social-icon"
									>
										<img
											src="/img/logo/whatsapp.svg"
											width="24px"
											height="24px"
										>
									</a>
									<a
										v-if="page.socialLinks.l"
										:href="page.socialLinks.l"
										target="_blank"
										class="ma-2 text-decoration-none navigation-link social-icon"
									>
										<img
											src="/img/logo/linkedin.svg"
											width="24px"
											height="24px"
										>
									</a>
								</div>
							</div>
						</v-col>
					</v-row>
					<v-divider class="my-8" />
					<v-row>
						<v-col cols="12 text-center justify-center align-center">
							<p>
								Made with ❤️ by <a
									href="https://www.dynamictrio.in/"
									class="text-decoration-none"
								>
									Team Dynamic Trio
								</a>
							</p>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>
	</div>
</template>

<script>
import { api, baseMixins } from "mixins";

export default {
	mixins: [baseMixins, api],
	data() {
		return {
			fab: false,
			page: {
				drawerNavigation: false,
				selectedItem: null,
				navigations: [],
				contact: {},
				socialLinks: {},
				subscribe: {
					form: {
						name: null,
						mobile: null,
					},
					validation: {
						requiredField: [(v) => !!v || "This Field is Required"],
					},
					resMsg: null,
					loading: null,
				},
			},
		};
	},
	mounted() {
		this.baseInit();
		this.getContactInfo();
	},
	methods: {
		baseInit() {
			this.getNavigation();
		},
		getNavigation() {
			this.page.navigations = [
				{
					title: "Navigations",
					value: [
						{
							title: "Home",
							link: "/",
							additional: false,
						},
						{
							title: "About Us",
							link: "/#about",
							additional: false,
						},
						{
							title: "Voyages For You",
							link: "/voyages-for-you",
							additional: false,
						},
						{
							title: "Review",
							link: "/#review",
							additional: false,
						},
						{
							title: "Contact Us",
							link: "/#contact",
							additional: false,
						},
						{
							title: "Terms & Conditions",
							link: "/document#terms-conditions",
							additional: true,
						},
						{
							title: "Privacy Policy",
							link: "/document#privacy-policy",
							additional: true,
						},
						{
							title: "Bank Detail",
							link: "/document#bank-detail",
							additional: true,
						},
					],
				},
			];
		},
		onScroll(e) {
			if (typeof window === "undefined") return;
			const top = window.pageYOffset || e.target.scrollTop || 0;
			this.fab = top > 20;
		},
		toTop() {
			this.$vuetify.goTo(0);
		},
		subscribeNow() {
			if (this.baseCheckBeforeCallApiFunction("subscribeForm")) {
				this.page.subscribe.loading = true;
				this.callApi(this.$axios, "f/subscribe/set", this.page.subscribe.form, { apiKey: "ev-f-sub-set" }).then((response) => {
					if (response) {
						this.page.subscribe.resMsg = response.msg;
						this.page.subscribe.loading = false;
					}
				});
			}
		},
		getContactInfo() {
			if (this.baseCheckBeforeCallApiFunction()) {
				this.callApi(this.$axios, "f/contact/get", {}, { apiKey: "ev-f-co-get" }).then((response) => {
					if (response && Object.keys(response).length > 0) {
						const cCode = response.contact.code;
						if (cCode === 200) {
							this.page.contact = response.contact.body.contact;
						}

						const sCode = response.social.code;
						if (sCode === 200) {
							this.page.socialLinks = response.social.body.socialLinks;
						}
					}
				});
			}
		},
	},
};
</script>

<style lang="scss">
	.nested-link {
		padding-left: 32px;
	}
	.logo-img{
		height: 70px;
		justify-content: center;
		align-items: center;
	}
	.navigation-link{
		color: var(--v-primaryText-base) !important;
	}
	.router-link-exact-active{
		color: var(--v-primary-base) !important;
	}
	.router-link-active{
		color: var(--v-primary-base) !important;
	}
	.footer-btn span.v-btn__content{
		opacity: 1 !important;
	}
	.social-icon {
		filter: brightness(0) invert(1);
		transition: all 0.3s ease-in-out;
		&:hover {
			filter: none;
		}
	}
	.top-bottom{
		right: 100px !important;
	}
	.top-slide{
		bottom: 60px !important;
	}
	.top-bottom-mobile{
		bottom: 100px !important
	}
</style>
